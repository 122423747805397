import { ParsedUrlQuery } from 'querystring'
import React from 'react'
import { Stack } from '@hub/stack'
import { setCacheHeaders } from '@scentregroup/shared/helpers/set-cache-headers'
import { StoreHeader } from '@scentregroup/shared/hub-components/store-header'
import RetailerStoresTileList from '@scentregroup/shared/hub-components/tile-list/retailer-stores-tile-list'
import { localeToCountry } from '@scentregroup/shared/helpers/local-to-country'
import { getDefaultNationalMetaData } from '@scentregroup/shared/helpers/get-default-national-metadata'
import Layout from '@scentregroup/shared/hub-components/layout'
import {
  getTopStripProps,
  ITopStripProps,
} from '@scentregroup/shared/hub-components/top-strip'
import { useGetHrefContext } from '@scentregroup/shared/context'
import {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
} from '../../../types/next'
import { makeApolloClient } from '../../../lib/apolloClient'
import { getNationalStorefront } from '../../../lib/page-templates/fetching'
import { getNationalStorefrontRetailer } from '../../../lib/get-national-storefront-retailer'
import { Retailer } from '@scentregroup/shared/types/posts'
import { SGPageTemplateWithAdZones } from '@scentregroup/shared/types/page-templates'
import { Template } from '@scentregroup/shared/components/page-templates/template'
import { getEnvBool } from '@scentregroup/shared/config'
import { completelyCanonicalUrl } from '@scentregroup/shared/helpers/canonical-link'
import {
  fetchNationalStaticData,
  NationalStaticData,
} from '@scentregroup/shared/helpers/fetch-header-static-data'

interface NationalStorefrontParams extends ParsedUrlQuery {
  id: string
  slug: string
}

interface NationalStorefrontProps {
  country: ReturnType<typeof localeToCountry>
  topStripProps: ITopStripProps | null
  retailer: Retailer
  template: SGPageTemplateWithAdZones | null
  nationalHeaderData: NationalStaticData
  isNationalStoreDirectory: boolean
}

interface FetchDataParams {
  country: 'Australia' | 'New Zealand'
  retailerId: string
}

interface FetchedData {
  nationalHeaderData: NationalStaticData
  template: SGPageTemplateWithAdZones | null
  retailer: Retailer | null
  topStripProps: ITopStripProps | null
}

type FetchData = (fetchDataParams: FetchDataParams) => Promise<FetchedData>

const fetchData: FetchData = async ({ country, retailerId }) => {
  const client = makeApolloClient()
  const [nationalHeaderData, template, retailer, topStripProps] =
    await Promise.all([
      fetchNationalStaticData(client, { country }),
      getNationalStorefront(client, retailerId),
      getNationalStorefrontRetailer(client, retailerId, country),
      getTopStripProps(client, country),
    ])
  return {
    nationalHeaderData,
    template,
    retailer,
    topStripProps,
  }
}

export async function getServerSidePropsWithFetchData({
  locale,
  params,
  fetchData,
}: Pick<
  GetServerSidePropsContext<NationalStorefrontParams>,
  'locale' | 'params'
> & {
  fetchData: FetchData
}): Promise<GetServerSidePropsResult<NationalStorefrontProps>> {
  // N.B. this is a server-side only feature flag so we get it directly
  // from process.env, MKP-7744:
  const isNationalStoreDirectory = getEnvBool(
    process.env.FEAT_FLAG_ENABLE_STORE_DIRECTORY
  )
  if (!params) {
    throw new Error('Required params not provided')
  }

  const country = localeToCountry(locale)
  const retailerId = params?.id
  if (!retailerId) {
    return {
      notFound: true,
    }
  }
  const { nationalHeaderData, template, retailer, topStripProps } =
    await fetchData({ country, retailerId })
  if (!retailer) {
    return {
      notFound: true,
    }
  }
  if (template === null && retailer.marketplaceShopSlug === null) {
    if (retailer.stores.length === 0) {
      return {
        notFound: true,
      }
    }
    if (retailer.stores.length === 1) {
      const [store] = retailer.stores
      return {
        redirect: {
          destination: `/${store.centre.slug}/store/${store.id}/${store.slug}`,
          permanent: false,
        },
      }
    }
  }
  if (retailer.slug !== params.slug) {
    return {
      redirect: {
        destination: `/store/${retailerId}/${retailer.slug}`,
        permanent: false,
      },
    }
  }

  return {
    props: {
      country,
      topStripProps,
      nationalHeaderData,
      template,
      retailer,
      isNationalStoreDirectory,
    },
  }
}

export async function getServerSideProps({
  locale,
  params,
  res,
}: GetServerSidePropsContext<NationalStorefrontParams>): Promise<
  GetServerSidePropsResult<NationalStorefrontProps>
> {
  setCacheHeaders(res)
  return getServerSidePropsWithFetchData({
    locale,
    params,
    fetchData,
  })
}

function calculateBreadcrumbs(
  retailerTitle: string,
  isNationalStoreDirectory: boolean
): React.ComponentProps<typeof Layout>['breadcrumbs'] {
  const home = { label: 'Home', url: '/' }
  const here = { label: retailerTitle }
  const findAStore = { label: 'Find a store', url: '/stores' }
  if (isNationalStoreDirectory) {
    return [home, findAStore, here]
  } else {
    return [home, here]
  }
}

function NationalStorefrontPage({
  country,
  topStripProps,
  retailer,
  template,
  nationalHeaderData,
  isNationalStoreDirectory,
}: NationalStorefrontProps): JSX.Element {
  const storefronteHeaderProps = {
    ...retailer,
    title: retailer.title,
    subTitle: '',
    enableH1Heading: true,
  }
  const canonical = completelyCanonicalUrl(useGetHrefContext()())
  const defaultMetaData = getDefaultNationalMetaData({
    country,
    type: 'storefront',
    retailer: retailer.title,
  })

  const metaData = React.useMemo<{
    title: string
    description: string
    canonical: string
  }>(() => {
    if (template) {
      const { title, shortDescription: description } = template
      const metaData = {
        title: title
          ? `Westfield ${country} | ${title}`
          : defaultMetaData.title,
        description: description || defaultMetaData.description,
        canonical,
      }
      return metaData
    } else {
      const metaData = {
        ...defaultMetaData,
        description: defaultMetaData.description,
        canonical,
      }
      return metaData
    }
  }, [canonical, country, defaultMetaData, template])

  if (template) {
    template.modules.forEach(module => {
      if (module.__typename === 'SGPageTemplateBasicContent') {
        module.titleAs = 'h2'
      }
    })
  }

  const storeTileRetailer = {
    title: retailer.title,
    slug: retailer.slug,
  }

  const breadcrumbs = React.useMemo(
    () => calculateBreadcrumbs(retailer.title, isNationalStoreDirectory),
    [isNationalStoreDirectory, retailer.title]
  )

  return (
    <Layout
      country={country}
      topStrip={topStripProps}
      centre={undefined}
      header={{
        variant: 'national',
        data: nationalHeaderData,
      }}
      metaData={metaData}
      breadcrumbs={breadcrumbs}
    >
      <Stack
        gap={['spacing-3xl', null, 'spacing-4xl']}
        sx={{
          paddingBottom: ['spacing-3xl', null, 'spacing-4xl'],
        }}
      >
        <StoreHeader {...storefronteHeaderProps} />
        {retailer.stores.length > 0 ? (
          <RetailerStoresTileList
            retailer={storeTileRetailer}
            stores={retailer.stores}
          />
        ) : null}
        {template && <Template template={template} activateH1Title={false} />}
      </Stack>
    </Layout>
  )
}

export default NationalStorefrontPage
