import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { Markdown } from '@scentregroup/shared/hub-components/markdown'
import { Box } from '@hub/box'
import { useToken } from '@hub/design-system-base'
import { CoreContainer } from '@hub/core-container'
import { Stack } from '@hub/stack'
import { Text } from '@hub/text'
import { Link } from '@hub/link'
import { Button } from '@hub/button'
// useMobileBreakpoint looks unavoidable here due to the checkReadMore logic
// eslint-disable-next-line no-restricted-syntax
import useMobileBreakpoint from '@scentregroup/shared/helpers/use-mobile-breakpoint'
import {
  CloudinaryImage,
  IMAGE_SIZES_HERO,
  IMAGE_SIZES_THUMBNAIL,
} from '../cloudinary-image'
import { useSupportingColors } from '../../hooks/use-supporting-colors'

const MAX_INITIAL_MOBILE_LINES_HEIGHT = 74
const MAX_INITIAL_DESKTOP_LINES_HEIGHT = 272

interface StoreHeaderProps {
  retailerLogo?: string | null
  title: string
  url?: string | null
  scrollToId?: string
  longDescription?: string | null
  subTitle?: string | null
  heroImage?: string | null
  nationalStorefrontUrl?: string
  enableH1Heading?: boolean
}

const MaybeLink: React.FC<
  React.PropsWithChildren<Partial<React.ComponentProps<typeof Link>>>
> = ({ href, ...rest }) => {
  return href ? <Link href={href} {...rest} /> : <>{rest.children}</>
}

export const StoreHeader: React.FC<
  React.PropsWithChildren<StoreHeaderProps>
> = ({
  retailerLogo,
  title,
  url = '',
  scrollToId = '',
  subTitle = '',
  longDescription: description,
  heroImage,
  nationalStorefrontUrl,
  enableH1Heading,
}) => {
  const squareImagePadding = useToken('space', 'spacing-md')
  const { supportingForegroundColor, supportingBackgroundColor } =
    useSupportingColors()
  const isMobile = useMobileBreakpoint()
  const [displayReadMore, setDisplayReadMore] = useState(false)
  const [readmore, setReadMore] = useState(true)
  const storeDescriptionRef = useRef(null)

  const descriptionMaxTextHeight = useMemo(
    () =>
      isMobile
        ? MAX_INITIAL_MOBILE_LINES_HEIGHT
        : MAX_INITIAL_DESKTOP_LINES_HEIGHT,
    [isMobile]
  )

  const checkReadMore = useCallback(
    (element: HTMLElement | null): void => {
      if (!element) {
        return
      }
      setDisplayReadMore(descriptionMaxTextHeight < element.scrollHeight)
    },
    [descriptionMaxTextHeight]
  )

  useEffect(() => {
    if (storeDescriptionRef.current) {
      checkReadMore(storeDescriptionRef.current)
    }
  }, [checkReadMore, description])

  const descriptionMaxHeight = useMemo(
    () => (readmore ? `${descriptionMaxTextHeight}px` : 'unset'),
    [readmore, descriptionMaxTextHeight]
  )

  return (
    <CoreContainer isMobileFullWidth>
      <Stack
        gap={['spacing-md', 'spacing-md', 'spacing-3xl']}
        direction={['column', 'column', 'row']}
        shouldWrapChildren={false}
        flexGrow={0}
        sx={{
          alignItems: ['left', 'left', 'top'],
          backgroundColor: supportingBackgroundColor,
          paddingY: 'spacing-md',
          paddingX: ['spacing-mobile-margin', 'spacing-md'],
        }}
      >
        <Stack
          shouldWrapChildren={false}
          sx={{
            color: supportingForegroundColor,
            flexBasis: [null, null, '70%'],
          }}
          gap="spacing-md"
        >
          <Stack
            shouldWrapChildren={false}
            direction="row"
            gap="spacing-md"
            align="center"
          >
            {retailerLogo && (
              <CloudinaryImage
                ratio="1"
                sx={{
                  minWidth: 'size-12',
                  maxWidth: 'size-12',
                }}
                imageSetOrImage={{
                  type: 'SQUARE',
                  url: retailerLogo,
                }}
                aria-labelledby={title}
                resizeMode="pad"
                sizes={IMAGE_SIZES_THUMBNAIL}
                loadingMode="eager"
              />
            )}
            <Box>
              <MaybeLink href={nationalStorefrontUrl}>
                <Text
                  isBodyText
                  as={enableH1Heading ? 'h1' : 'h3'}
                  fontSize={['font-h4', 'font-h4', 'font-h3']}
                  tone="strong"
                  sx={{ color: supportingForegroundColor }}
                >
                  {title}
                </Text>
              </MaybeLink>
              {url || scrollToId ? (
                <Link
                  hasUnderline
                  href={url || `#${scrollToId}`}
                  onClick={e => {
                    if (scrollToId) {
                      e.preventDefault()
                      document
                        ?.getElementById(scrollToId)
                        ?.scrollIntoView({ behavior: 'smooth' })
                    }
                  }}
                >
                  {subTitle && (
                    <Text sx={{ color: supportingForegroundColor }}>
                      {subTitle}
                    </Text>
                  )}
                </Link>
              ) : (
                subTitle && <Text>{subTitle}</Text>
              )}
            </Box>
          </Stack>
          <Box>
            <Box
              ref={storeDescriptionRef}
              sx={{
                maxHeight: descriptionMaxHeight,
                overflow: 'hidden',
                lineHeight: 'base',
              }}
            >
              <Markdown content={description} />
            </Box>
            {displayReadMore && (
              <Button
                sx={{
                  textDecoration: 'underline',
                  fontWeight: 'normal',
                  padding: 'spacing-none',
                  height: 'spacing-md',
                  textTransform: 'none',
                  color: supportingForegroundColor,
                }}
                variant="link"
                onClick={() => {
                  if (!readmore) {
                    window?.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                  setReadMore(!readmore)
                }}
              >
                {readmore ? 'See all' : 'See less'}
              </Button>
            )}
          </Box>
        </Stack>
        {heroImage && (
          <Box
            sx={{
              flexBasis: '30%',
              minWidth: `calc((100% / (21 / 9)) - (2 * ${squareImagePadding}))`,
            }}
          >
            <CloudinaryImage
              ratio="1"
              imageSetOrImage={{
                url: heroImage,
                type: 'WIDE',
              }}
              sizes={IMAGE_SIZES_HERO}
              loadingMode="eager"
            />
          </Box>
        )}
      </Stack>
    </CoreContainer>
  )
}
